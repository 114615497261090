<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <div class="sr-data-table-menu">
      <div class="sr-data-table-actions">
        <slot></slot>
      </div>
      <template v-if="propsReactive.search">
        <div class="sr-data-table-search">
          <label>Search</label>
          <n-input v-model:value="filterValue" placeholder="Search value" @update:value="debouncedHandleFilterChange" />
        </div>
      </template>
    </div>
    <n-data-table
      ref="dataTableRef"
      remote
      :bordered="false"
      :data="propsReactive.data.data"
      :loading="propsReactive.loading"
      :columns="columnsReactive"
      :pagination="paginationReactive"
      :scroll-x="propsReactive.scrollX"
      :scroll-y="propsReactive.scrollY"
      :row-key="propsReactive.rowKey"
      @update:sorter="handleSorterChange"
      @update:page="handlePageChange"
      @update:expanded-row-keys="handleExpandRowKeys"
    />
  </n-config-provider>
</template>

<script lang="ts" setup generic="T extends InternalRowData">
import { getDataTableThemeOverrides } from '../utils/theme-overrides';
import { type DataTableProps, type FixedResponsive } from '../common/interfaces/data-table/data-table-props.interface';
import {
  type DataTableSorterEmitParams,
  type DataTablePaginationEmitParams,
  type DataTableFilterEmitParams,
} from '../common/interfaces/data-table/data-table-emits.interface';
import { type DataTableColumns, type DataTableInst, type DataTableSortState } from 'naive-ui';
import type { InternalRowData, TableColumnGroup } from 'naive-ui/es/data-table/src/interface';
import debounce from 'debounce';

const themeOverrides = getDataTableThemeOverrides();

const dataTableRef: globalThis.Ref<DataTableInst | undefined> = ref();

function resetTable(): void {
  if (dataTableRef.value) {
    dataTableRef.value.clearFilters();
    dataTableRef.value.clearSorter();
    paginationReactive.page = 1;
  }
}

defineExpose({ resetTable });

const props = withDefaults(defineProps<DataTableProps<T>>(), {
  loading: false,
  data: () => ({
    data: [],
    total: 0,
  }),
});

const emits = defineEmits<{
  (e: 'updateSorter', options: DataTableSorterEmitParams): void;
  (e: 'updatePage', options: DataTablePaginationEmitParams): void;
  (e: 'updateFilter', options: DataTableFilterEmitParams): void;
  (e: 'updateExpandContent', id: number): void;
}>();

const propsReactive = reactive(props);

const paginationReactive = reactive({
  pageSize: propsReactive.pageSize,
  page: 1,
  pageCount: computed(() => Math.ceil(propsReactive.data.total / propsReactive.pageSize)),
  onChange: (page: number) => {
    paginationReactive.page = page;
  },
});

const filterValue = ref('');

const fixedColumns: { [key in keyof T]?: FixedResponsive } = {};

const columnsReactive: DataTableColumns<T> = reactive(
  propsReactive.columns.map((c) => {
    const fixedResponse = c.fixedResponsive;
    if (c.type === 'expand') {
      return { fixed: 'left', ...c };
    } else if (fixedResponse && fixedResponse.minWidth === 0) {
      return { ...c, fixed: fixedResponse.fixedPosition };
    } else if (fixedResponse) {
      fixedColumns[(c as TableColumnGroup<T>).key as keyof T] = fixedResponse;
    }

    return { ...c };
  }),
);

let sorterState: DataTableSortState;

function handleSorterChange(sorter: DataTableSortState): void {
  sorterState = sorter;
  paginationReactive.page = 1;

  emits('updateSorter', {
    filterValue: filterValue.value,
    sorter: sorterState,
  });
}

function handlePageChange(currentPage: number): void {
  emits('updatePage', {
    filterValue: filterValue.value,
    sorter: sorterState,
    currentPage,
  });
}

function handleFilterChange(): void {
  emits('updateFilter', {
    filterValue: filterValue.value,
    sorter: sorterState,
  });
}

const handleExpandRowKeys = (keys: Array<string | number>): void => {
  if (keys.length > 0) {
    emits('updateExpandContent', Number(keys.at(-1)));
  }
};

const debouncedHandleFilterChange = debounce(handleFilterChange, 500);

function handleResize(): void {
  for (const column of columnsReactive) {
    const typedColumn = column as TableColumnGroup<T>;
    const fixedResponse = fixedColumns[typedColumn.key as keyof T];

    if (!fixedResponse) {
      continue;
    }

    column.fixed = screen.width >= fixedResponse.minWidth ? fixedResponse.fixedPosition : undefined;
  }
}

const resizeEventHandler = debounce(handleResize, 100);

onMounted(() => {
  handleResize();
  window.addEventListener('resize', resizeEventHandler);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeEventHandler);
});
</script>

<style scoped lang="scss">
.sr-data-table-menu {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  width: 100%;

  padding: 12px;
}

.sr-data-table-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sr-data-table-search {
  width: 100%;
  height: auto;
  color: #ffffff;

  display: flex;
  flex-direction: row;

  align-items: center;

  label {
    margin-right: 12px;
    min-width: 40px;
  }
}

:deep(.n-data-table-th__title) {
  white-space: nowrap;
}

:deep(.nowrap-column) {
  white-space: nowrap;
}

@media (min-width: 428px) {
  .sr-data-table-menu {
    flex-direction: row;
    justify-content: space-between;
  }

  .sr-data-table-actions {
    flex-direction: row;
    width: auto;
  }

  .sr-data-table-search {
    width: 240px;

    div {
      align-content: center;
    }
  }
}
</style>
