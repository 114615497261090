<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-dropdown :options="propsReactive.menuItems" :trigger="'click'" class="sr-dots-menu">
      <n-icon :component="Dots" :size="iconSize" />
    </n-dropdown>
  </n-config-provider>
</template>

<script setup lang="ts">
import type { DropdownMixedOption } from 'naive-ui/es/dropdown/src/interface';
import { Dots } from '@vicons/tabler';

const themeOverrides = getDotsDropdownMenuThemeOverrides();

const props = defineProps<{
  menuItems: DropdownMixedOption[];
  size?: number;
}>();

const propsReactive = reactive(props);

const iconSize = computed(() => propsReactive.size ?? 25);
</script>

<style scoped lang="scss">
.n-icon {
  color: $white;
}
</style>
